// Bootstrap brand color customization

/*     brand Colors              */

$sw-blue: #242E7F;
$sw-yellow: #FBDC60;
$sw-gold: #ea9f21;

$brand-primary: $sw-blue !default;
$brand-success: $green !default;
$brand-danger: $red !default;
$brand-warning: $sw-gold !default;
$brand-info: $sw-blue !default;
$brand-rose: $pink !default;

$brand-inverse: $black-color !default;
