.card.card-dashboard {
  border-radius: 40px;
}
.card-dashboard {
  h3 {
      font-weight: 700;    
      font-size: 2rem;
      margin: 0 0 0 0;
  }
  h4 {
      font-weight: 400; 
  }
  p {
      margin: 0 0 0 0;
  }
  .dash-box {
      border-left: 2px solid;
  }
}