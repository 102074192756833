.card-form {

  .mat-mdc-form-field{
      font-size: small;
      display: flex;
    }

  .mat-mdc-select-value-text {
    font-size: small;
  }
  .card-body {
    padding-left: 15px;
    padding-right: 15px;
  }
  .form-group,
  .input-group {
    .form-control {
      margin-bottom: 0;
    }
  }
  .mdc-label {
      color: black !important;
  }



  form {
    margin: 0;
  }
  
  .btn {
    margin: 0;
  }
  .input-group .input-group-addon {
    padding-left: 0;
  }

  .bmd-form-group {
    padding-bottom: 0px;
    padding-top: 0;
  }
}
