.breadcrumbs {
  a {
    padding-bottom: 0px;
  }
}
.xng-breadcrumb-root {
  display: block;
  padding-left: 15px;
}
.xng-breadcrumb-link {
  display: flex;
  align-items: center;
  white-space: nowrap;
  color: inherit;
  text-decoration: none;
  transition: -webkit-text-decoration 0.3s;
  transition: text-decoration 0.3s;
  transition: text-decoration 0.3s, -webkit-text-decoration 0.3s;
  cursor: pointer;
  font-size: 14px;
  line-height: 1.42857;
  color: #aaaaaa;
  font-weight: 300;
}

.xng-breadcrumb-link,
label {
  font-size: 14px;
  line-height: 1.42857;
  color: #aaaaaa;
  font-weight: 300;
  margin-bottom: 0;
}

.btn .material-icons {
  font-size: 1.5rem;
}

.material-symbols-outlined {
  font-family: 'Material Symbols Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
}
