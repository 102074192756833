.index-page {
  .section-components h6.description {
    margin-top: 5px;
    margin-bottom: 30px;
  }

  .page-header {
    height: 90vh;
    overflow: hidden;
  }
  .brand {
    color: $white-color;
    text-align: center;

    h1 {
      font-size: 4.2rem;
      font-weight: 600;
      display: inline-block;
      position: relative;
    }
    h3 {
      font-size: 1.313rem;
      max-width: 500px;
      margin: 10px auto 0;
    }
  }

  h4.description {
    font-size: 1.5em;
  }
}
.section {
  padding: 70px 0;
}
.profile-page {
  .page-header {
    height: 380px;
    background-position: top center;
  }
  .profile {
    text-align: center;

    img {
      max-width: 160px;
      width: 100%;
      margin: 0 auto;
      @include transform-translate-y(-50%);
    }
    .name {
      margin-top: -80px;
    }
  }
  .follow {
    position: absolute;
    top: 0;
    right: 0;
  }
  .description {
    margin: $margin-base auto 0;
    max-width: 600px;
  }
  .profile-tabs {
    margin-top: $margin-base * 4;
  }
  .gallery {
    margin-top: $margin-base * 3;
    padding-bottom: 50px;

    img {
      width: 100%;
      margin-bottom: $margin-base * 2;
    }
  }
  .follow {
    .btn-fab {
      margin-top: -28px;
    }
  }
  .card-background {
    .content {
      padding: 30% 0;
      min-width: 160px;
    }
  }
  .work {
    padding: 40px 0px;
    .collections {
      margin-top: 20px;
    }

    .stats {
      ul > li {
        padding: 5px 0px;
        font-size: 1em;
        b {
          font-size: 1.2em;
        }
      }
    }
  }

  .connections {
    padding: 40px 0px;
    .card-profile {
      text-align: left;
    }
  }

  .tab-content {
    .collections {
      .card .card-body {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .badge {
          display: inline-table;
          margin: 0 auto;
        }
      }
    }
  }
}

.card-signup {
  .card-header {
    .social-line {
      .btn {
        color: $white-color;
      }
    }
  }
  .text-divider {
    margin-top: 30px;
    margin-bottom: 0px;
    text-align: center;
  }
}

.signup-page {
  .page-header {
    min-height: 100vh;
    height: auto;
    display: inherit;

    .container {
      padding-top: 20vh;
    }
  }

  .card-signup {
    border-radius: $border-radius-base * 2;
    @include shadow-16dp();
    margin-bottom: 100px;
    padding: 40px 0px;
  }
  .info-horizontal {
    padding: 0px 0px 20px;
  }

  .social {
    .btn {
      margin: 5px;
    }
    h4 {
      margin-top: 20px;
    }
  }
  .footer {
    .container {
      padding: 0;
    }
    .copyright,
    a {
      color: $white-color;
    }
  }
}

.card-header .avatar {
  max-width: 130px;
  max-height: 130px;
  margin-top: -60px;
  margin-right: 10px;
  border-radius: 50%;
  overflow: hidden;
  padding: 0;
  box-shadow: 0 16px 38px -12px rgb(0 0 0 / 56%), 0 4px 25px 0 rgb(0 0 0 / 12%),
    0 8px 10px -5px rgb(0 0 0 / 20%);
}
.avatar img {
  width: 100%;
  height: auto;
}

.table .avatar {
  max-width: 70px;
  max-height: 70px;
  border-radius: 50%;
  overflow: hidden;
  padding: 0;
  box-shadow: 0 16px 38px -12px rgb(0 0 0 / 56%), 0 4px 25px 0 rgb(0 0 0 / 12%),
    0 8px 10px -5px rgb(0 0 0 / 20%);
}


.swimmer .avatar {
  max-width: 130px;
  max-height: 130px;
  margin-top: 10px;
  margin-right: 10px;
  border-radius: 50%;
  overflow: hidden;
  padding: 0;
  box-shadow: 0 16px 38px -12px rgb(0 0 0 / 56%), 0 4px 25px 0 rgb(0 0 0 / 12%),
    0 8px 10px -5px rgb(0 0 0 / 20%);
}

