@mixin radio-color($color, $opacity) {
  & ~ .check,
  & ~ .circle {
    opacity: $opacity;
  }

  & ~ .check {
    background-color: $color;
  }

  & ~ .circle {
    border-color: $color;
  }
}

.form-check {
  .form-check-label {
    cursor: pointer;
    padding-left: 25px;
    @include mdb-label-color-toggle-focus();

    span {
      display: block;
      position: absolute;
      left: -1px;
      top: -1px;
      transition-duration: 0.2s;
    }
    .circle {
      border: 1px solid $mdb-radio-color-off;
      height: 15px;
      width: 15px;
      border-radius: 100%;
      top: 1px;

      .check {
        height: 15px;
        width: 15px;
        border-radius: 100%;
        background-color: $mdb-radio-color-on;
        @include transform-scale3d(unquote("0,0,0"));
      }
    }

    .form-check-input:not(:checked) ~ .check:after {
      @include animation(rippleOff 500ms);
    }

    .form-check-input:checked ~ .check:after {
      @include animation(rippleOff 500ms);
    }
  }

  .form-check-input {
    opacity: 0;
    height: 0;
    width: 0;
    overflow: hidden;

    &:checked {
      @include radio-color($mdb-radio-color-on, 1);
    }
    &:checked ~ .circle .check {
      @include transform-scale3d(unquote("0.65, 0.65, 1"));
    }
  }

  .form-check-input[disabled] {
    // light theme spec: Disabled: $black000, Opacity  26%
    @include radio-color($black, 0.26);

    & + .circle .check {
      background-color: $black;
    }
  }

  .form-check-sign {
    vertical-align: middle;
    position: relative;
    top: -2px;
    float: left;
    padding-right: 10px;
    display: inline-block;
  }
}

.form-check + .form-check {
  margin-top: 0;
}

.mat-radio-button {
  .mat-radio-container {
    height: 15px;
    width: 15px;
    .mat-radio-outer-circle {
      height: 15px;
      width: 15px;
      border-width: 1px;
    }

    .mat-radio-inner-circle {
      height: 15px;
      width: 15px;
      background-color: $mdb-radio-color-on;
    }
    .mat-radio-ripple {
      opacity: 0;
    }
  }
  &.mat-accent {
    &.mat-radio-checked {
      .mat-radio-outer-circle {
        border-color: $mdb-radio-color-on;
      }
      .mat-radio-inner-circle {
        transform: scale3d(0.65, 0.65, 1);
      }
    }
  }
  &.mat-radio-disabled {
    &.mat-accent {
      .mat-radio-outer-circle {
        border-color: $black;
        opacity: 0.26;
      }
      .mat-radio-inner-circle {
        background-color: $black;
        opacity: 0.26;
      }
    }
  }
}
