.card-results {
    .card-footer {
      padding-top: 0px !important;
      margin: 0px !important;

      .right {
        float: right;
        //border: 1px solid orange;
        .export {
          padding-right: 20px;
        }
        div {
          vertical-align: top;
          float: right;
        }
        .export {
          button {
            padding: 0px;
            margin: 0px;
          }
        }
        .togglebutton label 
        {
          text-transform: uppercase;
          font-size: 0.75rem;
          font-weight: 400;
          color: #999999;
        }
        .togglebutton .toggle {
          margin-right: 10px;
        }
      }
    }
  
}
