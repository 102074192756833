.card-swimmer {
  @extend %common-card-testimonial;
  margin-top: 50px;
}

.card-swimmer {
  .card-header:not([class*="card-header-"]) {
    background: transparent;
    box-shadow: none;
  }
  .card-body {
    margin-top: 0px;
  }
  .card-body {
    .logo {
      filter: drop-shadow(5px 5px 5px rgb(111, 111, 111));
    }
  }
  .card-avatar {
    max-width: 120px;
    max-height: 120px;
  }
  .card-title {
    i {
      font-size: larger;
    }
    font-size: 12pt;
  }
  .card-category {
    margin-top: -10px;
  }
}
.card-swimmer-rank {
  margin-top: 50px;

}


.card-swimmer-rank {
  margin-bottom: 0px !important;
  
  .card-title {
    text-transform: uppercase;
    font-weight: 900;
  }
  .card-footer {
    padding-top: 0px !important;
  }
  .card-body {
    h3 {
      margin-top: 0px;
    }
    .points {
      width:100%;
      height:auto;
      display:flex;
    }
    .badge {
      margin-top: -5px;
      filter: drop-shadow(5px 5px 5px rgb(111, 111, 111));
    }


    .rank {
      text-transform: uppercase;
    }
    .points {
      i {
        margin-left: 20px;
        font-weight: 900;
        ;
      }
      h3 {
        font-weight: 900;
      }
      .up_down {
        margin-top: 5px;
      }
      
    }
    .progress {
      display: flex;
      align-content: center;
      align-items: center;
      height: 30px;
      padding:0px;
      margin-bottom: 0px;
    }
    .progress {
      .percentage {
        position: absolute; 
        padding: 10px; 
        font-weight: bold;
        color: white;
      }
      .progress-bar {
        height: 30px;
        background-color: #242E7F;
        color: white;
        font-weight: bold;
      }
     
      
    }
  

    .mat-progress-bar-fill::after {
        background-color: #193b5c;
    }
    
  }
}
.card-stroke-rank-medley {
    margin-top: 50px;
  .card-title {
      text-transform: uppercase;
      font-weight: 900;
  }
  .card-footer {
    .stats {
      text-transform: uppercase;
      font-weight: 900;
      font-size: 12pt!important;
    }
  }
}
.card-stroke-rank  {
  
  .card-title {
    text-transform: uppercase;
    font-weight: 900;
  }
  
  .card-footer {
    .stats {
      text-transform: uppercase;
      font-weight: 900;
      font-size: 12pt!important;
    }
  }
}
.card-stroke-rank  {
    
  .card-title {
    text-transform: uppercase;
    font-weight: 900;
  }
  
  .card-footer {
    .stats {
      text-transform: uppercase;
      font-weight: 900;
    }
  }

   
  .progress {
    height: 30px;
    padding: 5px;
    .percentage {
      position: absolute; 
      padding: 10px; 
      font-weight: bold;
      color: white;
    }
  }
  .mat-progress-bar {
    height: 30px;
  }

  .mat-progress-bar-fill::after {
      background-color: #193b5c;
  }
}

.swimmers .avatar {
  min-width: 50px;
  min-height: 50px;

  img {
    width: 100%;
    height: auto;
  }
}
